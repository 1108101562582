import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import cheerio from 'cheerio';
import { DnDContext } from './resources/DnDContext';
import SearchBar from './SearchBar';
import PlayerCard from './PlayerCardComponents/PlayerCard';
import FormationDropdown from './FormationDropdown';
import Formations from './Formations';
import QueueVisualizer from './QueueVisualizer';
import './App.css';
import Logo from './resources/formationlogo.png';
import TeamViewer from './TeamViewer';
import yellowStar from './resources/yellow_star.png';
import whiteStar from './resources/white_star.png';
import HalfStar from './resources/half_star.png';
import { calculateOverall } from './OverallCalculation';
import addNewPlayer from './AddPlayer';
import BenchVisualizer from './BenchVisualizer';
import ReservesVisualizer from './ReservesVisualizer';
import TeamForm from './TeamForm';
import SelectTeamsModal from './SelectTeamsModal';
import DeleteTeamForm from './DeleteTeamForm';
import SoccerField from './GameSimulation/SoccerField';
import TeamPerformance from './TeamPerformance';
import LoginPopup from './Popups/LoginPopup';
import { addToLeague } from './GameSimulation/LeagueSimulation';
import { openDB } from 'idb';

export const getStatForPosition = (player, position) => {
  const isFootPreferenceMatch = (player, position) => {
    //Special case for left/right backs
    if (player.weakFoot === 5) return true;
    if ((position === 'LB' && player.prefFoot === 'Left') || (position === 'RB' && player.prefFoot === 'Right')) return true;
    if (position.startsWith('R') && player.prefFoot === 'Left' && position !== 'LB') return true;
    if (position.startsWith('L') && player.prefFoot === 'Right' && position !== 'RB') return true;
    return false;
  };

  if (!player.position) return;
  let score = 0;
  let nerf = 1;
  const posIndex = player.position.split(';').map(pos => pos.trim()).findIndex(pos => {
    if (pos === position) return true;
    //Case for if the current pos being checked is equal to positionName index
    if ((pos === 'ST' || pos.includes('F')) && position.includes('S')) {
      if (pos.includes('F')) {
        nerf = 0.9;
      }
      return true;
    }

    //CF cases
    if ((pos.includes('CF')) && position.includes('W')) {
      nerf = 0.85
      return true;
    }

    //Case for LW and RW being equivalent to Midfield positions
    if (((pos === 'RW') && (position === 'RAM' || position === 'RM')) || (pos === 'LW' && (position === 'LAM' || position === 'LM'))) {
      nerf = 0.95;
      return true;
    }

    //Case for if there are no more strikers and wingers have to play as strikers
    if ((pos === 'RW' || pos === 'LW') && (position.includes('S'))) {
      nerf = 0.7;
      return true;
    }

    //Case for 4-2-3-1 where there are 3 AM options, so CAM can play in those positions 
    if ((position === 'LAM' || position === 'RAM') && pos === 'CAM') {
      nerf = 0.9;
      return true;
    }

    //Case for if RM is main position
    if (((pos === 'RM') && (position === 'RAM' || position === 'RW')) || ((pos === 'LM') && (position === 'LAM' || position === 'LW'))) {
      nerf = 0.95;
      return true;
    }

    //Case for CM players who don't really play CDM
    if ((pos === 'CM' && !player.position.includes('CDM') && position.includes('DM')) || (pos === 'CAM' && !player.position.includes('CM') && position.includes('CM'))) {
      nerf = 0.85;
      return true;
    }

    //CAse for WING backs
    if (((pos === 'RWB') && (position === 'RM' || position === 'RB')) || ((pos === 'LWB') && (position === 'LM' || position === 'LB'))) {
      if (position.includes('M')) {
        nerf = 0.9;
      }
      return true;
    }

    //Case for CAMs that don't play CM
    if (pos === 'CAM' && position.includes('CM') && !player.position.includes('CM')) {
      nerf = 0.8;
      return true;
    }

    //Case for players that play cdm primarily
    if (pos === 'CDM' && !player.position.includes('CM') && (position.includes('CM') || position.endsWith('CB'))) {
      if (position.includes('CB')) {
        nerf = 0.75;
      }
      return true;
    }


    //Case for players who play CM and position is wide midfield
    if (pos === 'CM' && (position === 'LM' || position === 'RM')) {
      nerf = 0.7;
      return true;
    }

    if (pos === 'CB' && position.endsWith('CB')) {
      return true;
    }

    // General case for other positions not related to defenders
    return ((pos === 'CM' && position.endsWith(pos)) || (pos === 'CDM' && position.endsWith('DM'))) && !pos.endsWith('B') && pos;
  });

  if (posIndex !== -1) {
    score += 1000; // Base score for any listed position
    // Define boosts for each position priority (1st, 2nd, 3rd, etc.)
    const boosts = [1.3, 1.2, 1.1]; // Define boosts for top 3 priorities
    const boost = boosts[posIndex] || 1; // Default boost is 1 if position is beyond the 3rd
    score *= boost * nerf;
  }

  if (player.hasOwnProperty(position)) {
    // Split stat by '+' to get baseform
    let stat = Number(player[position]);
    let form = 0;
    if (player[position].includes('+')) {
      [stat, form] = player[position].split('+').map(Number);
    } else if (player[position].includes('-')) {
      [stat, form] = player[position].split('-').map(Number);
    }
    score += stat + form;

    if (isFootPreferenceMatch(player, position)) {
      score += 10;
    }

    //console.log(player.name + ' Stat for ' + position + '->\nStat:', (stat + form), '\nFoot: ', isFootPreferenceMatch(player, position) ? 10 : 0, '\nTotal: ' + score);
    // Adds up stat and form even if there's no + sign
    return score;
  } else {
    return -1; // Return -1 if the player doesn't have the specified position
  }
}

const CustomFormation = ({ token, handleLogout }) => {
  const options = [
    { value: '4-0-4-0-2', label: '4-4-2' },
    { value: '4-0-3-0-3', label: '4-3-3' },
    { value: '4-1-2-0-3', label: '4-3-3 (Holding)' },
    { value: '4-0-2-1-3', label: '4-3-3 (Attack)' },
    { value: '4-2-1-0-3', label: '4-3-3 (Defending)' },
    { value: '4-2-0-3-1', label: '4-2-3-1' },
    { value: '4-2-0-2-2', label: '4-2-2-2' },
    { value: '4-1-2-1-2', label: '4-1-2-1-2' },
    { value: '4-0-3-2-1', label: '4-3-2-1' },
    { value: '4-0-3-1-2', label: '4-3-1-2' },
    { value: '4-1-3-0-2', label: '4-1-3-2' },
    { value: '4-1-4-0-1', label: '4-1-4-1' },
    { value: '4-0-4-1-1', label: '4-4-1-1' },
    { value: '4-0-5-0-1', label: '4-5-1' },
    { value: '5-0-4-0-1', label: '5-4-1' },
    { value: '5-0-3-0-2', label: '5-3-2' },
    { value: '5-0-2-0-3', label: '5-2-3' },
    { value: '5-0-2-1-2', label: '5-2-1-2' },
    { value: '3-2-4-0-1', label: '3-2-4-1' },
    { value: '3-1-4-0-2', label: '3-1-4-2' },
    { value: '3-0-5-1-1', label: '3-5-1-1' },
    { value: '3-0-5-0-2', label: '3-5-2' },
    { value: '3-0-4-0-3', label: '3-4-3' },
    { value: '3-0-4-1-2', label: '3-4-1-2' },
    { value: '3-0-4-2-1', label: '3-4-2-1' }
  ];

  const getValueByLabel = (label) => {
    const option = options.find(option => option.label === label);
    return option.value;
  };

  const [username, setUsername] = useState('');
  const [tokens, setTokens] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const [formation, setFormation] = useState({ defenders: 4, dMidfielders: 0, midfielders: 4, aMidfielders: 0, strikers: 2 });
  const [dropFormation, setDropFormation] = useState('4-4-2');
  const [positions, setPositions] = useState([]);
  const [playersInitialized, setPlayersInitialized] = useState(false);
  const [playerData, setPlayerData] = useState([]);
  const [positionNames, setPositionNames] = useState(['LS', 'RS']);
  const [highlightedPosition, setHighlightedPosition] = useState({ key: null, name: null });
  const [queue, setQueue] = useState([]);
  const [teamPerformance, setTeamPerformance] = useState('');
  const [teamPlayers, setTeamPlayers] = useState({
    starting: [],
    sub: [],
    res: []
  });
  const [bench, setBench] = useState([]);
  const [reserves, setReserves] = useState([]);
  const [teamNameVisible, setTeamNameVisible] = useState(false);
  const [savedFormations, setSavedFormations] = useState([]);
  const [isFieldVisible, setIsFieldVisibile] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [teams, setTeams] = useState([]);
  const [editingTeam, setEditingTeam] = useState([]);
  //Gets home stadium of team
  const [homeStadium, setHomeStadium] = useState('');
  const [teamRating, setTeamRating] = useState(0);
  const [notLoggedInPopup, setNotLoggedInPopup] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [currentTeam, setCurrentTeam] = useState(null);
  const [addOrEdit, setAddOrEdit] = useState('add');

  //Calculate the rating of player in a formation
  const calculatePlayerRating = (player) => {
    const preferredPositions = player.position.split(';');
    const currentPosition = player.currentPosition.name;

    let positionRating = 0;
    let isPreferredPosition = false;

    if (preferredPositions.includes(currentPosition)) {
      isPreferredPosition = true;
    } else {
      if (player[currentPosition]) {
        const ratingString = player[currentPosition];
        if (ratingString.includes('+')) {
          const parts = ratingString.split('+');
          positionRating = parseInt(parts[0]) + parseInt(parts[1]);
        } else {
          positionRating = parseInt(ratingString);
        }
      }
    }

    let finalRating = 0;
    if (isPreferredPosition) {
      finalRating = (0.5 * player.overall) + (0.3 * player.potential) + (0.2 * 100);
    } else {
      finalRating = (0.5 * player.overall) + (0.3 * player.potential) + (0.2 * positionRating);
    }

    return finalRating ? parseFloat(finalRating) : 0;
  };

  const calculateFormationRating = (players) => {
    let totalRating = 0;
    players.forEach(player => {
      if (player) {
        totalRating += calculatePlayerRating(player);
      }
    });

    const formationRating = Math.round(totalRating / 11);
    return formationRating;
  }

  const getStarRating = (rating) => {
    if (isNaN(rating)) {
      rating = 0;
    }

    const maxStars = 5;
    const scaledRating = (rating / 90) * maxStars;
    const filledStars = Math.round(scaledRating);
    const halfStar = (scaledRating % 1 < 0.5 && scaledRating !== 0) ? 1 : 0;
    let emptyStars = maxStars - filledStars - halfStar;

    // Ensure emptyStars is not negative
    if (emptyStars < 0) {
      emptyStars = 0;
    }

    //console.log(`Scaled rating: ${scaledRating}, Filled Stars: ${filledStars}, Half Star: ${halfStar}, Empty stars: ${emptyStars}`)
    return (
      <>
        {Array(filledStars).fill().map((_, i) => (
          <img key={i} src={yellowStar} alt="Yellow Star" className="star" />
        ))}
        {halfStar === 1 && <img src={HalfStar} alt="Half Star" className="star" />}
        {Array(emptyStars).fill().map((_, i) => (
          <img key={filledStars + halfStar + i} src={whiteStar} alt="White Star" className="star" />
        ))}
      </>
    );
  };

  useEffect(() => {
    if (positions) {
      const formationRating = calculateFormationRating(Object.values(positions));
      setTeamRating(formationRating);
    }
  }, [positions])

  useEffect(() => {
    const loadAllFormations = async () => {
      try {
        const response = await axios.post('https://footballcustomformation.com/php_backend/load_positions.php', {
          file: 'saved_formations'
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          withCredentials: true
        });
        //console.log('Loaded data:', response.data);
        setSavedFormations(response.data);
      } catch (error) {
        console.error('Error loading formations:', error.response);
      }
    }
    loadAllFormations();
  }, [setSavedFormations])

  useEffect(() => {
    if (!playersInitialized) {
      //Loads in a formation if there is a file saved in .json file in saved_formations
      const loadPositionsFromServer = async () => {
        try {
          const response = await axios.post('https://footballcustomformation.com/php_backend/load_positions.php', {
            file: 'positions'
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            withCredentials: true
          });
          //console.log('Loaded data:', response.data);
          if (response.data) {
            const newQueue = response.data.queue;
            const positions = response.data.positionsData;
            const formation = response.data.dropFormation;
            setTeamPlayers(newQueue);

            //console.log('new Queue', newQueue);
            if (newQueue.starting) {
              newQueue.starting = newQueue.starting.map(player => {
                if (!player.type) {
                  player.type = 'queue';
                }
                return player;
              });
              setQueue(newQueue.starting);
            }

            // Check and set player.type for each player in newQueue.sub
            if (newQueue.sub) {
              newQueue.sub = newQueue.sub.map(player => {
                if (!player.type) {
                  player.type = 'bench';
                }
                return player;
              });
              setBench(newQueue.sub);
            }

            // Check and set player.type for each player in newQueue.res
            if (newQueue.res) {
              newQueue.res = newQueue.res.map(player => {
                if (!player.type) {
                  player.type = 'reserves';
                }
                return player;
              });
              setReserves(newQueue.res);
            }

            setDropFormation(formation);

            const fullValue = getValueByLabel(formation);

            handleFormationSelect(fullValue);
            if (positions) {
              setPositions(positions);
            }
          }
          if (response.data.homeStadium) {
            setHomeStadium(response.data.homeStadium);
          }
        } catch (error) {
          console.error('Error loading positions:', error.response);
        }
      };
      loadPositionsFromServer();
    }
  }, []);

  //Use effect to determine username
  useEffect(() => {
    if (token) {
      const fetchUsername = async () => {
        try {
          const response = await axios.get('https://footballcustomformation.com/php_backend/get_username.php', {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          //console.log('User found successfully', response.data);
          setUsername(response.data.username);
          setTokens(response.data.tokens);
          setCanEdit(response.data.canEdit);
        } catch (error) {
          console.error('Error fetching username:', error);
        }
      }

      fetchUsername();
    }
  }, [token]);

  // Function to save positions array to a JSON file
  const savePositionsToServer = async (positionsData) => {
    if (!token) {
      setNotLoggedInPopup(true);
      return;
    }

    const allPositions = { starting: queue, sub: bench, res: reserves };
    axios.post('https://footballcustomformation.com/php_backend/save_positions.php', {
      queue: allPositions,
      dropFormation,
      positionsData,
      homeStadium
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      withCredentials: true
    })
      .then((response) => {
        console.log('Successful response:', response.data);
        alert('Players in team have been saved!');
      })
      .catch((error) => {
        console.error('Positions unable to be saved', error.response);
      });
  };

  const saveTeam = (name, captain, addOrEdit) => {
    const teamExists = teams.some(team => team.displayName === name);

    if (teamExists) {
      alert('A team with this name already exists. Please choose a different name.');
    }

    if (addOrEdit === 'add') {
      setTeamNameVisible(false);
      addNewFormation(name, captain);
    } else {
      console.log(`${name} will be edited with ${captain} as captain`);
      setTeamNameVisible(false);
      saveFormationChanges(name, captain);
    }
  }

  const checkQueueAndPositions = (positions, newQueue) => {
    if (!positions || !newQueue) {
      alert('Discrepancy found: Positions and/or queue are not defined');
      return true;
    }
    //Check if players in filteredPositions and queue aren't the same
    const positionPlayerIds = Object.values(positions).map(player => player.id);
    const queuePlayerIds = newQueue.map(player => player.id);

    const discrepancy = positionPlayerIds.some(id => !queuePlayerIds.includes(id));

    if (discrepancy) {
      alert('Discrepancy found: Some players in the formation are not in the queue. Please try again!');
      return true;
    }

    return false;
  };
  const addNewFormation = async (name, captain) => {
    if (!name) return;

    const filteredPositions = Object.fromEntries(
      Object.entries(positions).filter(([key, value]) => {
        if (key !== 'undefined') {
          return true;
        } else {
          console.log('Removing undefined key', value);
          return false;
        }
      })
    );

    const newBench = Array.isArray(bench) ? bench : Object.values(bench);

    const discrepency = checkQueueAndPositions(filteredPositions, queue);

    if (discrepency) return;

    const captainPlayer = Object.values(filteredPositions).find(player => player && player.name === captain);

    const allData = {
      teamId: savedFormations.length,
      displayName: name,
      dropFormation: dropFormation,
      formation: formation,
      positionsData: filteredPositions,
      queue: queue,
      bench: newBench,
      reserves: reserves,
      captain: captainPlayer,
      homeStadium: homeStadium
    };
    console.log('Add Formation: Data to be saved', allData);
    axios.post('https://footballcustomformation.com/php_backend/add_formation.php', {
      formation: allData
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      withCredentials: true
    })
      .then((response) => {
        console.log('Successful response:', response.data);
        alert(`New Team ${name} has been saved!`);
        setEditingTeam(allData);
        setSavedFormations(prevFormations => [...prevFormations, allData]);
      })
      .catch((error) => {
        console.error('Positions unable to be saved', error.response);
      });
    if (canEdit) {
      const newTeam = {
        teamId: currentTeam ? currentTeam.team_id : null,
        teamName: currentTeam ? currentTeam.team_name : 'TBD',
        starting: filteredPositions,
        sub: bench,
        res: reserves,
        dropFormation: dropFormation,
        formation: formation,
        captain: captainPlayer.id
      };
      if (window.confirm('Would you like to add new team to sql?')) {
        await addToLeague(newTeam, 'add');
      }
    }
  }

  const deleteFormation = async (id) => {
    if (id < 0) {
      console.error('Team id not defined!', id);
      return; 
    }
    try {
      await axios.post('https://footballcustomformation.com/php_backend/delete_formation.php', {
        teamId: id,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true
      });
      console.log('Successfully deleted');
      alert(`Team ${id} has been deleted!`);
      setEditingTeam(null);
      setSavedFormations(prevFormations => prevFormations.filter(formation => formation.teamId !== id));
    } catch (error) {
      console.error('Error deleting team!', error);
    };
  }

  //Positions and formations parameters 
  const positionParams = {
    Striker: 'strikers',
    aMidfielder: 'aMidfielders',
    Midfielder: 'midfielders',
    dMidfielder: 'dMidfielders',
    Defender: 'defenders'
  };

  const addPlayerToPosition = (player) => {
    if (highlightedPosition) {
      console.log('Highlighted', highlightedPosition);
      const updatedPosition = { ...positions, [highlightedPosition.key]: player }
      player.currentPosition = { key: highlightedPosition.key, name: highlightedPosition.modifiedName }
      setPositions(updatedPosition);
    }
  };

  const getPositionType = (positionName) => {
    if (positionName.includes('B')) return 'Defender';
    if (positionName.includes('CM') || positionName.includes('LM') || positionName.includes('RM')) return 'Midfielder';
    if (positionName.endsWith('AM')) return 'aMidfielder';
    if (positionName.includes('S') || positionName.includes('W')) return 'Striker';
    if (positionName.endsWith('DM')) return 'dMidfielder';
  }

  const handlePlayerClick = (player) => {
    if (highlightedPosition.key && highlightedPosition.name) {
      addPlayerToPosition(player, highlightedPosition);
      console.log('Player added to: ', highlightedPosition)
      setHighlightedPosition({ key: '', name: '' }); // Reset selected position after adding the player
    } else {
      if (Object.keys(positions).length < 11) {
        addByPosition(player);
      } else {
        alert('Remove a player or double click on position to replace');
      }
    }
  };

  //Clears positions array
  const handleClear = () => {
    setPositions([]);
  }

  const handleFormationSelect = (selectedFormation) => {
    const [defenders, dMidfielders, midfielders, aMidfielders, strikers] = selectedFormation.split('-').map(Number);
    setFormation({ defenders, dMidfielders, midfielders, aMidfielders, strikers });
  };

  const handleSearch = (filteredPlayers) => {
    setSearchResults(filteredPlayers);
  };

  //Adds player to eleven player queue
  const addToQueue = (playerQueue, setFunction, player) => {
    const playerType = playerQueue === queue ? 'queue' : setFunction === setBench ? 'bench' : 'reserves';
    const updatedPlayer = { ...player, type: playerType };

    setFunction([...playerQueue, updatedPlayer]);
    console.log(player.name + ' added to ' + playerType);
  };

  const removeFromQueue = (setFunction, position) => {
    setFunction(prevQueue => {
      const updatedQueue = prevQueue.filter((player, index) => index !== position);
      return updatedQueue;
    });
  };

  const clearQueue = (clearFunction, confirm = true) => {
    if (confirm) {
      if (window.confirm('Are you sure you want to delete this queue?')) {
        clearFunction([]);
      }
    } else {
      clearFunction([]);
    }
  }

  //Updates the playercard t0 editedPlayer
  const updatePlayer = (position, updatedPlayerInfo) => {
    setPositions(prevPositions => ({
      ...prevPositions,
      [position.key]: updatedPlayerInfo // Remove player from position
    }));

    // Update player in the queue if present
    setQueue(prevQueue => {
      // Find the index of the player in the queue using a unique identifier (e.g., id)
      const playerIndex = prevQueue.findIndex(player => player.id === updatedPlayerInfo.id);

      // If the player is found in the queue, update that player's information
      if (playerIndex !== -1) {
        const newQueue = [...prevQueue];
        newQueue[playerIndex] = updatedPlayerInfo;
        return newQueue;
      }

      // If the player is not found in the queue, return the previous queue without changes
      return prevQueue;
    });

    setBench(prevBench => {
      const playerIndex = prevBench.findIndex(player => player.id === updatedPlayerInfo.id);

      if (playerIndex !== -1) {
        const newBench = [...prevBench];
        newBench[playerIndex] = updatedPlayerInfo;
        return newBench;
      }

      return prevBench;
    });

    setReserves(prevReserves => {
      const playerIndex = prevReserves.findIndex(player => player.id === updatedPlayerInfo.id);

      if (playerIndex !== -1) {
        const newReserves = [...prevReserves];
        newReserves[playerIndex] = updatedPlayerInfo;
        return newReserves;
      }

      return prevReserves;
    });
  }

  //Saves changes to a player object to sql database
  const saveAllChanges = async (editedPlayer) => {
    console.log('Edited player', editedPlayer);
    try {
      // Update the player data
      const response = await axios.post('https://footballcustomformation.com/php_backend/update_players.php', {
        player: editedPlayer
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      });
      console.log('Successful response:', response.data);
      const updatedPlayerData = playerData.map(oldPlayer => {
        if (oldPlayer.id === editedPlayer.id) {
          return { ...editedPlayer };
        }
        return oldPlayer;
      });
      updatePlayer(editedPlayer.currentPosition, editedPlayer); // Call onUpdate with the updated player information
      setPlayerData(updatedPlayerData);
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  //Saves everyone in the queue
  const handleUpdateAll = () => {
    const allPlayers = [...queue]
    allPlayers.push(...bench);
    allPlayers.push(...reserves);

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    const fetchPlayersWithDelay = async (allPlayers) => {
      for (const player of allPlayers) {
        // Simulate a delay between 100ms and 400ms
        const randomDelay = Math.floor(Math.random() * (400 - 100 + 1)) + 100;

        // Fetch player stats and calculate overall
        getPlayerStats(player, `http://localhost:3001/FIFAFormationCreator/player/${player.id}`);
        calculateOverall(player);

        // Wait for the delay
        await delay(randomDelay);
      }
    };

    fetchPlayersWithDelay(allPlayers);
    if (window.confirm('Would you like to enter a team to replace?')) {
      const replacementTeam = window.prompt('Input name of club and logo separated by ;');
      if (!replacementTeam.includes(';')) {
        return;
      }
      const team = replacementTeam.split(';')[0];
      const teamLogo = replacementTeam.split(';')[1];
      allPlayers.forEach(player => {
        if (player.club !== team || player.clubLogo !== teamLogo) {
          player.club = team;
          player.clubLogo = teamLogo;
        }
      });
    }
    savePositionsToServer(positions);
    alert('Everyone has been updated in queue!');
  };

  const handleSaveChanges = async (playerId) => {
    let editedPlayer = playerData.find(player => player.id === playerId);
    if (editedPlayer) {
      alert('Player has already been added!');
      return;
    }
    try {
      editedPlayer = await addNewPlayer(playerId);
      if (editedPlayer === null) {
        alert('Player could not be found!');
        return;
      }
      await saveAllChanges(editedPlayer);
      const result = [editedPlayer];
      setSearchResults(result);
      setPlayerData([...playerData, editedPlayer]);
    } catch (error) {
      console.error('Player could not be found!', error);
      setSearchResults(null);
    }
  };

  //Finds the best position for each player
  const findPlayersForEachFormation = (positionNames, currentPlayers) => {
    if (currentPlayers.length < 11) {
      return '';
    }

    if (positions && !playersInitialized) {
      setPlayersInitialized(true);
      console.log('Players Initialized set to true');
      return '';
    }

    console.log('Positions Names: ', positionNames);

    const bestPlayers = {};
    let availablePlayers = [...currentPlayers];
    const filledPositions = {};

    // Initialize filledPositions with all position names set to false
    positionNames.forEach(position => {
      filledPositions[position] = false;
    });

    for (let i = 0; i < positionNames.length; i++) {
      const position = positionNames[i];
      // Iterate over player's stats and find the best available position
      const sortedPlayers = availablePlayers.sort((a, b) => {
        const statA = getStatForPosition(a, position);
        const statB = getStatForPosition(b, position);
        return statB - statA;
      }); //sortedPlayers
      //console.log('Sorted Players for ', position, ': ', sortedPlayers);

      // Find the first player that hasn't been assigned to a position
      if (sortedPlayers.length > 0) {
        const bestPlayer = sortedPlayers[0];
        bestPlayers[position] = bestPlayer;
        availablePlayers = availablePlayers.filter(player => player.name !== bestPlayer.name);
      }
    } //positionNames for

    console.log("Best Players ", bestPlayers);

    const updatedPositions = {};

    for (let i = 0; i < positionNames.length; i++) {
      const player = bestPlayers[positionNames[i]];
      const position = positionNames[i];

      if (positionNames.includes(position) && player) {
        //console.log('Current position:', positionNames[i]);
        if (position === 'GK') {
          updatedPositions['Goalkeeper-0'] = player;
          player.currentPosition = { key: 'Goalkeeper-0', name: position };
        } else {
          const param = positionParams[getPositionType(position)]; //formation variable
          for (let j = 0; j < formation[param]; j++) {
            const positionKey = `${getPositionType(position)}-${j}`;
            if (!updatedPositions[positionKey]) {
              updatedPositions[positionKey] = player;
              player.currentPosition = { key: positionKey, name: position };
              //console.log(player.name, 'has been added to', positionKey);
              break;
            }
          }
        }
      }
    }
    console.log('Updated positions', updatedPositions);
    setPositions(updatedPositions); // Update the positions state
    return bestPlayers;
  };

  const addByPosition = (player) => {
    if (Object.values(positions) > 11) {
      alert("The formation is full! Delete a player and try again");
    }
    const getPositionNamesIndex = (positionType, index) => {
      let cumulativeIndex = 0;
      for (const key in positionParams) {
        if (positionParams[key] === positionParams[positionType]) break;
        //console.log(`positionParams key: ${positionParams[key]} | Formation: ${positionParams[positionType]}`);
        cumulativeIndex += formation[positionParams[key]];
      }
      return cumulativeIndex + index;
    }

    const positionScores = positionNames.map(position => ({
      position,
      score: getStatForPosition(player, position)
    }))
    positionScores.sort((a, b) => b.score - a.score);
    const updatedPositions = { ...positions };
    console.log('Updated positions', updatedPositions);
    let found = false;

    for (const { position } of positionScores) {
      if (positionNames.includes(position) && player) {
        if (position === 'GK' && !positions['Goalkeeper-0']) {
          updatedPositions['Goalkeeper-0'] = player;
          player.currentPosition = { key: 'Goalkeeper-0', name: position };
          break;
        } //Works
        const positionType = getPositionType(position);
        const param = positionParams[positionType];
        const positionCount = formation[param];
        let i = 0;
        let cumulativeIndex = 0;
        for (i = 0; i < positionCount; i++) {
          cumulativeIndex = getPositionNamesIndex(positionType, i);
          if (positionNames[cumulativeIndex] === position) break;
        }
        const positionKey = `${positionType}-${i}`;
        if (!updatedPositions[positionKey]) {
          updatedPositions[positionKey] = player;
          player.currentPosition = { key: positionKey, name: positionNames[cumulativeIndex] };
          found = true;
          break;
        }
        if (found) break;
      }
    }

    setPositions(updatedPositions);
  }

  //Fetches stats from html in SoFifa
  //Url: `http://localhost:3001/FIFAFormationCreator/player/${player.id}`
  const getPlayerStats = async (editedPlayer, url) => {
    axios.get(url).then(response => {
      const html = response.data;
      const $ = cheerio.load(html);
      const attributes = {}; // Object to hold all attributes

      //Find birthday:
      const profile = $('.profile.clearfix');
      const playerInfoText = profile.find('p').text();

      const heightMatch = playerInfoText.match(/\b\d{3}cm\s\/\s\d+'\d{1,2}"/);
      const height = heightMatch ? heightMatch[0] : '17x cm';

      const weightMatch = playerInfoText.match(/\b\d+kg \/ \d+lbs\b/);
      const weight = weightMatch ? weightMatch[0] : '5x kg';

      // Use a regular expression to extract the birthday
      const birthdayMatch = playerInfoText.match(/\(([^)]+)\)/);
      const birthday = birthdayMatch ? birthdayMatch[1] : 'Not found';

      const parsedBirthday = new Date(birthday);
      const formattedBirthday = parsedBirthday.toISOString().split('T')[0];

      const birthdayDate = new Date(formattedBirthday);
      const today = new Date();
      let age = today.getFullYear() - birthdayDate.getFullYear();
      const m = today.getMonth() - birthdayDate.getMonth();

      const clubElement = $('.col').has('h5:contains("Club")').find('a[href*="/team/"]').first();
      const club = clubElement.text().trim();
      const clubLogo = clubElement.find('img.avatar').attr('data-src');

      //If birthday hasn't passed in the year
      if (m < 0 || (m === 0 && today.getDate() < birthdayDate.getDate())) {
        age--;
      }

      const ageString = `${birthday}; ${age}`;

      $('div.col').find('p').each((i, el) => {
        const textContent = $(el).contents().filter(function () {
          return this.type === 'text';
        }).text().trim();

        const label = $(el).find('label').text();

        if (label) {
          attributes[label] = textContent;
        }
      });

      // Assuming all attributes are within a div.grid.attribute block
      $('div.grid.attribute').each((i, elem) => {
        // Find all <p> elements within this block
        $(elem).find('p').each((j, pElem) => {
          // Assuming the structure is always <p><em>value</em> <span>attribute name</span></p>
          let attributeName = $(pElem).find('span').text().trim(); // Get the attribute name
          attributeName = attributeName.replace(/[+-]\d+\s*/, '').trim();
          const attributeValue = $(pElem).find('em').text().trim(); // Get the attribute value

          attributes[attributeName] = attributeValue; // Store in the object
        });
      });

      const positions = profile.find('span.pos').map(function () {
        return $(this).text();
      }).get();

      if (!editedPlayer.position) {
        console.log('Player doesn\'t have any positions')
        editedPlayer.position = positions.join('; ');
      } else {
        //Gets any positions not already included in editedPlayer.position
        positions.forEach(pos => {
          // If the editedPlayer.position string does not include the current pos,
          // append it to the editedPlayer.position string
          if (!editedPlayer.position.includes(pos)) {
            editedPlayer.position += `; ${pos}`;
          }
        });
      }

      if (!editedPlayer.fullName) {
        editedPlayer.fullName = profile.find('h1').text();
      }
      editedPlayer.age = ageString;
      editedPlayer.overall = $('.grid .col').find('.sub:contains("Overall rating")').siblings('em').attr('title');
      editedPlayer.potential = $('.grid .col').find('.sub:contains("Potential")').siblings('em').attr('title');
      editedPlayer.intRep = attributes['International reputation'];
      editedPlayer.height = height;
      editedPlayer.weight = weight;
      //editedPlayer.club = club ? club : editedPlayer.club;
      //editedPlayer.clubLogo = clubLogo ? clubLogo : editedPlayer.clubLogo;
      editedPlayer.acceleration = attributes['Acceleration'];
      editedPlayer.sprintSpeed = attributes['Sprint speed'];
      editedPlayer.pace = Math.round(attributes['Acceleration'] * 0.45 + attributes['Sprint speed'] * 0.55);
      editedPlayer.positioning = attributes['Att. Position']
      editedPlayer.shortPassing = attributes['Short passing'];
      editedPlayer.finishing = attributes['Finishing'];
      editedPlayer.shotPower = attributes['Shot power'];
      editedPlayer.longShots = attributes['Long shots'];
      editedPlayer.prefFoot = attributes['Preferred foot'];
      editedPlayer.weakFoot = attributes['Weak foot'];
      editedPlayer.skillMoves = attributes['Skill moves'];
      editedPlayer.volleys = attributes['Volleys'];
      editedPlayer.penalties = attributes['Penalties'];
      editedPlayer.fkAccuracy = attributes['FK Accuracy'];
      editedPlayer.shooting = Math.round(attributes['Att. Position'] * 0.05 + attributes['Finishing'] * 0.45 + attributes['Long shots'] * 0.2 + attributes['Penalties'] * 0.05 + attributes['Shot power'] * 0.2 + attributes['Volleys'] * 0.05);
      editedPlayer.vision = attributes['Vision'];
      editedPlayer.crossing = attributes['Crossing'];
      editedPlayer.shortPassing = attributes['Short passing'];
      editedPlayer.longPassing = attributes['Long passing'];
      editedPlayer.curve = attributes['Curve'];
      editedPlayer.passing = Math.round(attributes['Vision'] * 0.2 + attributes['Crossing'] * 0.2 + attributes['Curve'] * 0.05 + attributes['FK Accuracy'] * 0.05 + attributes['Long passing'] * 0.15 + attributes['Short passing'] * 0.35);
      editedPlayer.agility = attributes['Agility'];
      editedPlayer.balance = attributes['Balance'];
      editedPlayer.reactions = attributes['Reactions'];
      editedPlayer.ballControl = attributes['Ball control'];
      editedPlayer.skill_dribbling = attributes['Dribbling'];
      editedPlayer.dribbling = Math.round(attributes['Agility'] * 0.1 + attributes['Balance'] * 0.05 + attributes['Reactions'] * 0.05 + attributes['Ball control'] * 0.3 + attributes['Dribbling'] * 0.5);
      editedPlayer.composure = attributes['Composure'];
      editedPlayer.interceptions = attributes['Interceptions'];
      editedPlayer.headingAccuracy = attributes['Heading accuracy'];
      editedPlayer.marking = attributes['Defensive awareness'];
      editedPlayer.standingTackle = attributes['Standing tackle'];
      editedPlayer.slidingTackle = attributes['Sliding tackle'];
      editedPlayer.defending = Math.round(attributes['Interceptions'] * 0.2 + attributes['Heading accuracy'] * 0.1 + attributes['Defensive awareness'] * 0.3 + attributes['Sliding tackle'] * 0.1 + attributes['Standing tackle'] * 0.3);
      editedPlayer.jumping = attributes['Jumping'];
      editedPlayer.stamina = attributes['Stamina'];
      editedPlayer.strength = attributes['Strength'];
      editedPlayer.aggression = attributes['Aggression'];
      editedPlayer.physical = Math.round(attributes['Aggression'] * 0.2 + attributes['Jumping'] * 0.05 + attributes['Stamina'] * 0.25 + attributes['Strength'] * 0.5);
      editedPlayer.gkDiving = attributes['GK Diving'];
      editedPlayer.gkHandling = attributes['GK Handling'];
      editedPlayer.gkKicking = attributes['GK Kicking'];
      editedPlayer.gkReflex = attributes['GK Reflexes'];
      editedPlayer.gkPos = attributes['GK Positioning'];
      editedPlayer.gkSpeed = editedPlayer.pace;

      console.log('Attributes', attributes);

      saveAllChanges(editedPlayer);
    }).catch((error) => { // Corrected this line
      console.error(`Data unable to be retrieved from ${editedPlayer.name}`, error); // Corrected this line
    });
  };

  //Sorts the queue based on a stat
  const sortQueue = (position, queue, setSortedFunction) => {
    const newQueue = queue.sort((a, b) => {
      const getStatValue = (player) => {
        const statValue = player[position];
        // Split the stat value into base and increment parts
        const parts = statValue.split('+');
        let base = parseInt(parts[0], 10);
        const increment = parts.length > 1 ? parseInt(parts[1], 10) : 0;
        if (player.position.includes(position)) {
          base += 1000;
        }
        return base + increment;
      };

      const aValue = getStatValue(a);
      const bValue = getStatValue(b);

      return bValue - aValue;
    });

    setSortedFunction(newQueue);
  }

  const isInAQueue = (player) => {
    if (queue.some(p => p.id === player.id) || bench.some(p => p.id === player.id) || reserves.some(p => p.id === player.id)) return true;
    return false;
  }

  /**
   * Creates the best team based on a player's nationality if it doesn't exist on the fifateams database.]
   * @param team Filters the players by this country
   * @param positionNames names of positions to filter players 
   */
  const createTeam = (team) => {
    let filteredPlayers;
    if (team.type === 'country') {
      filteredPlayers = playerData.filter(player => player.nationality.includes(team.name));
    } else {
      filteredPlayers = playerData.filter(player => player.club.includes(team.name));
    }
    if (filteredPlayers.length < 18) {
      alert('Not enough players');
      return null;
    }
    //Map for best player in each position
    const bestPlayersByPosition = [];
    const selectedPlayers = new Set();

    positionNames.forEach(position => {
      const sortedPlayers = filteredPlayers.sort((a, b) => getStatForPosition(b, position) - getStatForPosition(a, position));

      for (const player of sortedPlayers) {
        if (!selectedPlayers.has(player.id)) {
          bestPlayersByPosition.push(player);
          selectedPlayers.add(player.id);
          break;
        }
      }
    });

    const bench = [];
    const reserves = [];

    //Adds the best players by overall to bench and reserves
    const playersByOverall = filteredPlayers.sort((a, b) => b.overall - a.overall);
    playersByOverall.forEach(player => {
      if (!selectedPlayers.has(player.id)) {
        if (bench.length < 9) {
          bench.push(player);
        } else if (reserves.length < 15) {
          reserves.push(player);
        }
        selectedPlayers.add(player.id);
      }
    });

    console.log(bestPlayersByPosition);
    setQueue(bestPlayersByPosition);
    setBench(bench);
    setReserves(reserves);
    return bestPlayersByPosition;
  }

  const pushToQueue = (player, destination) => {
    const queueIsFull = (array) => {
      alert(`The ${array} is full. Move/Remove a player from ${array} and try again`);
    };
    if (player.type === 'queue') {
      setQueue(queue => queue.filter(p => p.id !== player.id));
      if (destination === 'bench') {
        if (bench.length >= 10) {
          queueIsFull('bench');
          return;
        }
        setBench(bench => [...bench, player]);
        player.type = 'bench';
      } else if (destination === 'reserves') {
        if (bench.length >= 18) {
          queueIsFull('reserves');
          return;
        }
        setReserves(reserves => [...reserves, player]);
        player.type = 'reserves';
      }
    } else {
      if (queue.length < 11) {
        setQueue(queue => [...queue, player]);
        if (player.type === 'bench') {
          setBench(bench => bench.filter(p => p.id !== player.id));
        }

        else if (player.type === 'reserves') {
          setReserves(reserves => reserves.filter(p => p.id !== player.id));
        }
        player.type = 'queue';
      } else {
        queueIsFull('queue');
      }
    }
  };

  const handleTeamSelection = (event) => {
    const selectedName = event.target.value;
    const selectedTeam = savedFormations.find(team => team.displayName === selectedName);
    setEditingTeam(selectedTeam);
    console.log('Selected team', selectedTeam);
    if (selectedTeam) {
      setQueue(selectedTeam.queue);
      setBench(selectedTeam.bench);
      setReserves(selectedTeam.reserves);
      setDropFormation(selectedTeam.dropFormation);
      setPlayersInitialized(false);
      setFormation(selectedTeam.formation);
      setPositions(selectedTeam.positionsData);
      setHomeStadium(selectedTeam.homeStadium);
      if (selectedTeam.performance) {
        setTeamPerformance(selectedTeam.performance);
      } else {
        setTeamPerformance('');
      }
    }
  }

  const saveFormationChanges = (newName, newCaptain) => {
    const cleanPositionsData = (positionsData) => {
      return Object.fromEntries(
        Object.entries(positionsData).filter(([key, value]) => key !== 'undefined')
      );
    };

    const newPositions = cleanPositionsData(positions);
    const discrepancy = checkQueueAndPositions(newPositions, queue);
    if (discrepancy) return;

    const captainPlayer = Object.values(newPositions).find(player => player.name === newCaptain);
    const updatedFormations = savedFormations.map(team => {
      if (team.teamId === editingTeam.teamId) {
        if (team && newCaptain) {
          return {
            ...team,
            displayName: newName,
            captain: captainPlayer,
            formation: formation,
            dropFormation: dropFormation,
            positionsData: newPositions,
            bench: bench,
            queue: queue,
            reserves: reserves,
            performance: teamPerformance,
            homeStadium: homeStadium
          };
        }
      }
      return team;
    })

    const updatedTeam = updatedFormations.find(team => team.teamId === editingTeam.teamId);

    axios.post('https://footballcustomformation.com/php_backend/edit_formation.php', {
      formation: updatedTeam,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      withCredentials: true
    })
      .then((response) => {
        console.log('Successful response:', response.data);
        alert(`Team has been updated!`);
        setSavedFormations(updatedFormations);
      })
      .catch((error) => {
        console.error('Positions unable to be saved', error);
      });
  }

  const handleDrop = (item, targetIndex, target) => {
    const player = item.player;

    //console.log('Handle drop called.\nPlayer:', item, '\nTarget:', target);

    if (item.source === target) {
      const setArray = target === 'bench' ? setBench : setReserves;

      setArray((prevArray) => {
        const newArray = [...prevArray];

        if (newArray[targetIndex]) {
          // Regular swap if target is not empty
          [newArray[targetIndex], newArray[item.index]] = [newArray[item.index], newArray[targetIndex]];
        } else {
          // Move player to an empty slot and shift other players
          newArray.splice(item.index, 1); // Remove player from source position
          newArray.splice(targetIndex, 0, player); // Insert player at target position
        }

        return newArray;
      });
    } else {
      // Move player to an empty slot in the target array and shift other players
      const setSourceArray = item.source === 'bench' ? setBench : setReserves;
      const setTargetArray = target === 'bench' ? setBench : setReserves;

      setSourceArray((prevSourceArray) => {
        const newSource = [...prevSourceArray];
        newSource.splice(item.index, 1); // Remove player from source position
        return newSource;
      });

      setTargetArray((prevTargetArray) => {
        const newTarget = [...prevTargetArray];

        if (newTarget[targetIndex]) {
          // Regular swap if target is not empty, and update types
          const targetPlayer = { ...newTarget[targetIndex], type: item.source };
          newTarget[targetIndex] = { ...player, type: target };
          setSourceArray((prevSourceArray) => {
            const newSource = [...prevSourceArray];
            newSource.splice(item.index, 0, targetPlayer); // Update the type of the source player
            return newSource;
          });
        } else {
          // Move player to an empty slot, update type
          newTarget.splice(targetIndex, 0, { ...player, type: target });
        }

        return newTarget;
      });
    }
  };

  const handleStatsChange = (playerId, stats, type) => {
    if (type === 'queue') {
      const newQueue = queue.map(player =>
        player.id === playerId ? { ...player, ...stats } : player
      )
      //console.log('New queue:', newQueue);
      setQueue(newQueue);

      const newPositions = { ...positions };
      Object.keys(newPositions).some(key => {
        if (newPositions[key].id === playerId) {
          newPositions[key] = { ...newPositions[key], ...stats };
          return true;
        }
        return false;
      });
      setPositions(newPositions);
    } else if (type === 'bench') {
      const newBench = bench.map(player =>
        player.id === playerId ? { ...player, ...stats } : player
      );
      setBench(newBench);
    } else if (type === 'reserves') {
      const newReserves = reserves.map(player =>
        player.id === playerId ? { ...player, ...stats } : player
      );
      setReserves(newReserves);
    }
  }

  const handleDoubleClick = () => {
    const newStadium = prompt('Enter new Home Stadium:', homeStadium);
    if (newStadium !== null && newStadium.trim() !== '') {
      setHomeStadium(newStadium);
    }
  }

  const handleAddNewTeam = (teamEdit) => {
    if (token) {
      setTeamNameVisible(true);
    } else {
      setNotLoggedInPopup(true);
      setAddOrEdit(teamEdit);
    }
  }

  const checkTokens = async (changeTokens) => {
    if (changeTokens) {
      const newTokens = tokens - 1;
      setTokens(newTokens);
      try {
        const response = await axios.post('https://footballcustomformation.com/php_backend/update_tokens.php', {
          username: username,
          tokens: newTokens,
        });
        console.log('Successfully updated AI tokens', response.data);
      } catch (error) {
        console.error('Error updating AI tokens:', error);
      }
    } else {
      if (tokens > 0) {
        return true;
      }
      return false;
    }
  }

  const savePlayerDataToIndexedDB = async (playerData) => {
    const db = await openDB('PlayerDB', 1, {
      upgrade(db) {
        db.createObjectStore('playerData');
      },
    });

    await db.put('playerData', playerData, 'data');
  };

  //Function to add a bunch of new players;
  const addManyNewPlayers = async () => {
    /*
    for (let i = 0; i < missingPlayers.length; i++) {
      console.log('Adding', missingPlayers[i]);
      await handleSaveChanges(missingPlayers[i]);
    } */
  };

  const clearTeam = () => {
    if (window.confirm('Would you like to create a new team?')) {
      clearQueue(setQueue, false);
      clearQueue(setBench, false);
      clearQueue(setReserves, false);
      setEditingTeam(null);
      handleClear();
      setCurrentTeam(null);
    } else {
      return;
    }
  }

  const setPlayersFromTeam = (team) => {
    const getPlayerFromData = (id) => {
      return playerData.find(p => p.id === id);
    }

    setDropFormation(team.dropFormation);
    setFormation(team.formation);
    setPlayersInitialized(false);

    //Sets queue
    let newQueue = [];
    const positionsData = {};
    team.positionsData.forEach(originalPlayer => {
      const player = getPlayerFromData(originalPlayer.id);
      newQueue.push(player);
      player.currentPosition = originalPlayer.currentPosition;

      const key = originalPlayer.currentPosition.key;
      positionsData[key] = player;
    });
    setQueue(newQueue);
    setPositions(positionsData);

    //Sets bench
    newQueue = [];
    team.bench.forEach(playerId => {
      const player = getPlayerFromData(playerId);
      newQueue.push(player);
    });
    setBench(newQueue);

    newQueue = [];
    //Sets reserves
    team.reserves.forEach(playerId => {
      const player = getPlayerFromData(playerId);
      newQueue.push(player);
    });
    setReserves(newQueue);

    setEditingTeam(false);
  }

  const updateLeagueTeam = () => {
    if (currentTeam && canEdit) {
      const filteredPositions = Object.fromEntries(
        Object.entries(positions).filter(([key, value]) => {
          if (key !== 'undefined') {
            return true;
          } else {
            console.log('Removing undefined key', value);
            return false;
          }
        })
      );

      const newTeam = {
        teamId: currentTeam.team_id,
        teamName: currentTeam.team_name,
        starting: filteredPositions,
        sub: bench,
        res: reserves,
        dropFormation: dropFormation,
        formation: formation,
        captain: currentTeam.captain
      };

      addToLeague(newTeam, 'edit');
    }
  };

  let canEditTeam = (editingTeam && editingTeam.displayName);
  return (
    <div className="App">
      <header className="title-header">
        <div className="logo-container">
          <img src={Logo} alt="Logo" className="logo" />
          <h1 className="title">FIFA Player Manager</h1>
        </div>
        <div className="header-login-button">
          {token && username ? (
            <button className="logout-button" onClick={() => handleLogout(setUsername)}>Log Out</button>
          ) : (
            <Link to="/login" className="login-link">Login</Link>
          )}
        </div>
      </header>
      {username && tokens && (
        <h3 className="welcome-message">Welcome back, {username}! You have {tokens} AI tokens.</h3>
      )}
      {notLoggedInPopup && <LoginPopup onClose={() => setNotLoggedInPopup(false)} />}
      <select className="select-dropdown" onChange={handleTeamSelection}>
        {savedFormations.length > 0 ? (
          savedFormations.map((team, index) => (
            <option key={index} value={team.displayName}>
              {team.displayName}: {team.dropFormation}
            </option>
          ))
        ) : (
          <option>No teams loaded</option>
        )}
      </select>
      {teamNameVisible && (
        <TeamForm
          setTeamName={saveTeam}
          setVisibility={setTeamNameVisible}
          positions={positions}
          canEdit={addOrEdit}
        />
      )}
      <QueueVisualizer
        queue={queue}
        setQueue={setQueue}
        removeFromQueue={removeFromQueue}
        clearQueue={clearQueue}
        findBestPosition={findPlayersForEachFormation}
        positionNames={positionNames}
        bench={bench}
        setBench={setBench}
        reserves={reserves}
        setReserves={setReserves}
        sortQueue={sortQueue}
        onStatsChange={handleStatsChange}
        saveAllChanges={saveAllChanges}
        pushToQueue={pushToQueue}
        addByPosition={addByPosition} />
      <h2 className="custom-title">
        <i className="fa fa-futbol-o"></i> Football Custom Formation <i className="fa fa-futbol-o"></i>
        <small className="custom-subtitle">Design your dream team formation</small>
      </h2>
      <FormationDropdown
        dropFormation={dropFormation}
        setDropFormation={setDropFormation}
        options={options}
        handleFormationSelect={handleFormationSelect}
      />
      <div className="formation-button-group">
        <button className="clear-button clear" onClick={() => handleClear()}>Clear</button>
        {canEdit && currentTeam && <button className="clear-button save" onClick={() => updateLeagueTeam()}>Update {currentTeam.team_name}</button>}
        <button className='clear-button save' onClick={() => savePositionsToServer(positions)}>Save Layout</button>
        <button className="clear-button add-new-team" onClick={() => handleAddNewTeam('add')}>Add New Team</button>
        <button className="clear-button create-new-team" onClick={clearTeam}>Create A New Team</button>
        {editingTeam && editingTeam.displayName && (
          <div className='name-button-container'>
            <button className='clear-button edit-team' onClick={() => setTeamNameVisible(true)}>Edit Team Name</button>
            <button
              className="clear-button update-team"
              onClick={() => saveFormationChanges(editingTeam.displayName, editingTeam.captain.name)}
            >
              Update {editingTeam.displayName}
            </button>
          </div>
        )}
        {canEdit && (
          <button className='clear-button update-all' onClick={() => handleUpdateAll()}>Update Everyone</button>
        )}
      </div> {/*Button to update a team if they are being edited*/}
      <DeleteTeamForm savedFormations={savedFormations} deleteTeam={deleteFormation} />
      <TeamViewer
        createTeam={createTeam}
        setHomeStadium={setHomeStadium}
        setCurrentTeam={setCurrentTeam}
        setPlayersFromTeam={setPlayersFromTeam}
      />
      <div className="formation-editor">
      {savedFormations.length >= 2 && (
        <>
          <button className="emoji-button" onClick={() => setModalOpen(true)}>⚽ Choose Teams for Simulation 🚀</button>
          {modalOpen && (
            <SelectTeamsModal
              onClose={() => setModalOpen(false)}
              setFieldOpen={setIsFieldVisibile}
              savedFormations={savedFormations}
              setTeams={setTeams}
            />
          )}
        </>
      )}
      {playerData.length > 15000 &&
        <Link
          to="/league_simulator"
          onClick={() => savePlayerDataToIndexedDB(playerData)}
          className="simulate-league-button"
        >
          <span className="simulate-league-icon">⚙️</span> Simulate League
        </Link>
      }
      </div>
      {editingTeam && editingTeam.displayName && (
        <TeamPerformance
          performance={teamPerformance}
          setPerformance={setTeamPerformance} />
      )}
      <div className="team-info">
        {positions && (
          <p className="team-rating">
            Team Rating: <span className="stars">{getStarRating(teamRating)}</span> {teamRating}
          </p>
        )}
        <div className="home-stadium-container">
          <p
            className="home-stadium"
            onDoubleClick={handleDoubleClick}
          >
            Home Stadium: <u>{homeStadium}</u>
          </p>
        </div>
      </div>
      {isFieldVisible && (
        <SoccerField
          setIsOpen={setIsFieldVisibile}
          teams={teams}
          setTeams={setTeams}
          saveAllChanges={saveAllChanges}
          checkTokens={checkTokens}
        />
      )}
      <Formations
        formation={formation}
        positions={positions}
        setPositions={setPositions}
        setPositionNames={setPositionNames}
        highlightedPosition={highlightedPosition}
        setHighlightedPosition={setHighlightedPosition}
        addPlayerToPosition={addPlayerToPosition}
        findBestPlayers={findPlayersForEachFormation}
        saveAllChanges={saveAllChanges}
        queue={queue}
        getPlayerStats={getPlayerStats}
      />
      <DnDContext>
        <BenchVisualizer
          bench={bench}
          setBench={setBench}
          reserves={reserves}
          setReserves={setReserves}
          removeFromQueue={removeFromQueue}
          clearBench={clearQueue}
          sortQueue={sortQueue}
          handleDrop={handleDrop}
          onStatsChange={handleStatsChange}
          pushToQueue={pushToQueue}
          addByPosition={addByPosition}
        />
        <ReservesVisualizer
          reserves={reserves}
          setReserves={setReserves}
          removeFromQueue={removeFromQueue}
          clearReserves={clearQueue}
          handleDrop={handleDrop}
          onStatsChange={handleStatsChange}
          pushToQueue={pushToQueue}
          addByPosition={addByPosition}
        />
      </DnDContext>
      <h2>Add Players Here</h2>
      <div className="save-changes">
        <input type="text" placeholder="Enter NEW Player ID" id="playerIdInput" />
        <button onClick={() => handleSaveChanges(document.getElementById('playerIdInput').value)}>+</button>
      </div>
      <SearchBar
        onSearch={handleSearch}
        playerData={playerData}
        setPlayerData={setPlayerData} />
      <div className="player-cards">
        <ul>
          {searchResults.map((player, index) => (
            <div key={index} className="player-card-container">
              <PlayerCard
                player={player}
                saveAllChanges={saveAllChanges}
                getPlayerStats={getPlayerStats} />
              <div className="button-group">
                <button
                  className="styled-button"
                  onClick={() => handlePlayerClick(player)}
                  disabled={Object.values(positions).some((pos) => pos && pos.id === player.id) || (Object.keys(positions).length >= 11 && highlightedPosition.name === '')}>
                  Add To Formation
                </button>
                <button
                  className="styled-button"
                  onClick={() => addToQueue(queue, setQueue, player)}
                  disabled={queue.length >= 11 || isInAQueue(player) || (player.position === 'GK' && queue.some(p => p.position === 'GK'))}
                >
                  Add To Queue
                </button>
                <button
                  className="styled-button"
                  onClick={() => addToQueue(bench, setBench, player)}
                  disabled={bench.length >= 10 || isInAQueue(player)}
                >
                  Add To Bench
                </button>
                <button
                  className="styled-button"
                  onClick={() => addToQueue(reserves, setReserves, player)}
                  disabled={reserves.length >= 18 || isInAQueue(player)}
                >
                  Add To Reserves
                </button>
              </div>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CustomFormation;