import React, { useEffect, useState } from 'react';
import defaultImage from '../resources/default_player.png';
import isEqual from 'lodash/isEqual';
import Ball from './Ball';
import { simulateMatch, assignCaptain } from './SimulateMatch';
import './SoccerField.css';
import captainArmband from '../resources/captain.png';
import MatchResultPopup from './MatchResultPopup';
import { generateMatchPrediction } from '../resources/openai/chatConfig';
import AIPredictionPopup from './AIPredictionPopup';

/**
 * Maps out players in a soccer field to simulate a team
 * @param {*array of Home and away team that will be competing} teams  
 * @returns soccer field layout
 */
const SoccerField = ({ teams, setTeams, setIsOpen, checkTokens }) => {
    const [isSimulating, setIsSimulating] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);
    
    const [matchResults, setMatchResults] = useState(null);
    const [captainAssigned, setCaptainAssigned] = useState(false);
    const [predictionOpen, setPredictionOpen] = useState(false);
    const [prediction, setPrediction] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const PITCH_WIDTH = 95;
    const PITCH_LENGTH = 100;
    const originalCaptain1 = teams[0]?.captain || null;
    const originalCaptain2 = teams[1]?.captain || null;
    const [headToHead, setHeadToHead] = useState({ wins: 0, draws: 0, losses: 0 });
    const token = localStorage.getItem('token');

    const initializeTeamPosition = (team, teamSide, formation) => {
        const baseX = teamSide === 'home' ? 5 : PITCH_WIDTH - 5; // Base x-coordinate for the team side
        const direction = teamSide === 'home' ? 1 : -1; // Direction to place players in order

        //console.log('Current team for', teamSide, team.positionsData, '\nFormation:', formation);
        const updatedPositions = Object.entries(team.positionsData).reduce((acc, [key, player]) => {
            let x, y;
            const positionType = key.split('-')[0];
            const positionNum = parseInt(key.split('-')[1], 10);
            //console.log('Position num: ', positionNum);

            let roleIndex = 0;
            let numPositions = '';
            // Y-coordinate positioning by role, scales with PITCH_LENGTH
            switch (positionType) {
                case 'Defender':
                    roleIndex = 1;
                    numPositions = formation.defenders;
                    break;
                case 'dMidfielder':
                    roleIndex = 2;
                    numPositions = formation.dMidfielders;
                    break;
                case 'Midfielder':
                    roleIndex = 3;
                    numPositions = formation.midfielders;
                    break;
                case 'aMidfielder':
                    roleIndex = 4;
                    numPositions = formation.aMidfielders;
                    break;
                case 'Striker':
                    roleIndex = 5;
                    numPositions = formation.strikers;
                    break;
                case 'Goalkeeper':
                    x = baseX;
                    y = PITCH_LENGTH / 2;
                    break;
                default: // For goalkeepers or undefined roles
                    // Center vertically
                    break;
            }
            if (positionType !== 'Goalkeeper') {
                if (teamSide === 'home') {
                    y = PITCH_LENGTH / (numPositions + 1) * (positionNum + 1);
                } else { // For 'away' team, invert the y positioning
                    y = PITCH_LENGTH - (PITCH_LENGTH / (numPositions + 1) * (positionNum + 1));
                }
                x = baseX + direction * roleIndex * 7; // Assuming each role spaced by 60 pixels horizontally
            }

            // Append transformed data
            acc[key] = { ...player, x: x, y: y - 2 };
            return acc;
        }, {});
        return { ...team, positionsData: updatedPositions };
    }

    // Use useEffect to run the update function when teams are set or changed
    useEffect(() => {
        const updatedTeams = teams.map(team => initializeTeamPosition(team, team.side, team.formation));
        if (!isEqual(teams, updatedTeams)) {
            setTeams(updatedTeams);
            setCaptainAssigned(false);
        }
    }, [teams, setTeams]);

    useEffect(() => {
        if (!captainAssigned) {
            const updatedTeams = teams.map(team => {
                if (team.captain) {
                    if (team.captain.name === originalCaptain1.name || team.captain.name === originalCaptain2.name) return team;
                }
                const captainName = team.captain || assignCaptain(team);

                return {
                    ...team,
                    captain: captainName,
                };
            });
            setTeams(updatedTeams);
            setCaptainAssigned(true);
        }
    }, [teams, setTeams, captainAssigned]);

    const handleSimulation = async (isCup) => {
        setIsSimulating(true);
        const results = await simulateMatch(teams[0], teams[1], 'team1', isCup);
        teams[0].captain = originalCaptain1;
        teams[1].captain = originalCaptain2;
        setMatchResults(results);
        console.log('Results', results);
        setPopupVisible(true);
        setCaptainAssigned(false);
        setIsSimulating(false);
        return results;
    }

    const handleHeadToHeadChange = (e) => {
        const { name, value } = e.target;
        setHeadToHead(prev => ({ ...prev, [name]: value }));
    }

    const handleGeneratePrediction = async () => {
        setIsLoading(true);
        const predictionResult = await generateMatchPrediction(teams, headToHead, token);
        setPrediction(predictionResult);
        setIsLoading(false);
        setPredictionOpen(true);
    }

    return (
        <div className="field">
            <button className="btn close-btn" onClick={() => setIsOpen(false)}>Close</button>
            <div className="head-to-head-container">
                <div className="head-to-head-field">
                    <label>Wins</label>
                    <input
                        type="number"
                        name="wins"
                        min="0"
                        value={headToHead.wins}
                        onChange={handleHeadToHeadChange}
                        placeholder="Wins"
                    />
                </div>
                <div className="head-to-head-field">
                    <label>Draws</label>
                    <input
                        type="number"
                        name="draws"
                        min="0"
                        value={headToHead.draws}
                        onChange={handleHeadToHeadChange}
                        placeholder="Draws"
                    />
                </div>
                <div className="head-to-head-field">
                    <label>Losses</label>
                    <input
                        type="number"
                        name="losses"
                        min="0"
                        value={headToHead.losses}
                        onChange={handleHeadToHeadChange}
                        placeholder="Losses"
                    />
                </div>
            </div>

            {teams.map((team, index) => (
                <div key={index} className={`team team-${index}`}>
                    {Object.values(team.positionsData).map((player) => (
                        <div
                            key={player.name}
                            className="player"
                            style={{ top: `${player.y}%`, left: `${player.x}%` }}
                        >
                            <img src={player.photo} onError={(e) => e.target.src = defaultImage} alt={player.name} style={{ width: '50px', height: '50px' }} />
                            {team.captain && team.captain.name === player.name && (
                                <img
                                    src={captainArmband}
                                    alt="Captain Armband"
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        position: 'absolute',
                                        bottom: '0',
                                        right: '0'
                                    }}
                                />
                            )}
                            <span className="player-field">{player.name}: {player.currentPosition.name} </span>
                        </div>
                    ))}
                </div>
            ))}
            <Ball fieldWidth={1150} fieldHeight={750} />
            <div className="button-container">
                <button className="btn simulate-btn" style={{ backgroundColor: 'red' }}
                    onClick={handleGeneratePrediction}>AI Prediction</button>
                <button className="btn simulate-btn" onClick={() => handleSimulation()} disabled={isSimulating}>
                    {isSimulating ? 'Simulating...' : 'Simulate'}
                </button>
            </div>
            {(popupVisible && matchResults) && (
                <MatchResultPopup
                    teams={teams}
                    setIsVisible={setPopupVisible}
                    results={matchResults}
                    setTeams={setTeams}
                    simulateAgain={handleSimulation}
                    checkTokens={checkTokens}
                />
            )}
            {predictionOpen && !isLoading && (
                <AIPredictionPopup
                    prediction={prediction}
                    onClose={() => setPredictionOpen(false)}
                />
            )}
            {isLoading && (
                <div className="loading-container">
                    <div className="loading-bar">
                        <div className="loading-progress"></div>
                    </div>
                    <p>Loading...</p>
                </div>
            )}
        </div>
    );
}

export default SoccerField;

