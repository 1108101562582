import React, { useState, useEffect, useCallback } from 'react';
import '@szhsin/react-menu/dist/index.css';
import defaultImage from '../resources/default_player.png';
import PlayerStatsPopup from '../StatsPopup';

const PlayerCard = ({ player, saveAllChanges, getPlayerStats }) => {
    const [playerStats, setPlayerStats] = useState([]);
    const [imageUrl, setImageUrl] = useState(player.photo);
    const [errorCount, setErrorCount] = useState(0);
    const [statsPopupOpen, setStatsPopupOpen] = useState(false);
    const [lastTap, setLastTap] = useState(0);

    useEffect(() => {
        const positions = player.position.split(";");
        player.mainPosition = positions[0];
    }, [player.position]);

    const handleTap = (e) => {
        e.preventDefault();
        const now = Date.now();
        const DOUBLE_TAP_DELAY = 300; // 300ms delay for double-tap detection

        if (now - lastTap < DOUBLE_TAP_DELAY) {
            setStatsPopupOpen(true); // Trigger the double-click function if it's a double-tap
        } else {
            setLastTap(now);
        }
    };

    useEffect(() => {
        let statsToAdd = [];
        if (player.mainPosition === "GK") {
            statsToAdd = [
                { posName: "DIV", stat: player.gkDiving },
                { posName: "HAN", stat: player.gkHandling },
                { posName: "KIC", stat: player.gkKicking },
                { posName: "REF", stat: player.gkReflex },
                { posName: "SPD", stat: player.gkSpeed },
                { posName: "POS", stat: player.gkPos }
            ];
        } else {
            statsToAdd = [
                { posName: "PAC", stat: player.pace },
                { posName: "SHO", stat: player.shooting },
                { posName: "PAS", stat: player.passing },
                { posName: "DRI", stat: player.dribbling },
                { posName: "DEF", stat: player.defending },
                { posName: "PHY", stat: player.physical }
            ];
        }

        setPlayerStats(statsToAdd);
    }, [player.mainPosition]);

    useEffect(() => {
        setImageUrl(player.photo);
        setErrorCount(0); // Reset error count when player changes
    }, [player]);

    const toggleStatsPopup = () => {
        setStatsPopupOpen(!statsPopupOpen);
    };

    const handleImageError = () => {
        if (errorCount === 0) {
            const paddedId = player.id.toString().padStart(6, '0');
            const part1 = paddedId.substring(0, 3);
            const part2 = paddedId.substring(3);
            const firstFallback = `https://cdn.sofifa.net/players/${part1}/${part2}/23_120.png`;

            setImageUrl(firstFallback);
            setErrorCount(1); // Update to the first fallback
        } else if (errorCount === 1) {
            setImageUrl(defaultImage);
            setErrorCount(2); // Update to the default image
        }
    };

    return (
        <div className="player-card"
            onDoubleClick={() => setStatsPopupOpen(true)}
            onTouchEnd={handleTap}>
            <div className="left-panel">
                <div className="player-info">
                    <h2>{player.overall}</h2>
                    <h3><span dangerouslySetInnerHTML={{ __html: player.mainPosition }} /></h3>
                    <img src={player.clubLogo} alt="Club Logo" className="club-logo" />
                    <img src={player.flag} alt="Country Flag" className="country-flag" />
                </div>

            </div>
            <div className="right-panel">
                <img src={imageUrl} onError={handleImageError} alt={player.name} className="player-photo" />
                <h3>{player.name}</h3>

                <div className="attribute-group">
                    <div className="attribute-section">
                        {playerStats.slice(0, 3).map((stat, index) => (
                            <div className="attribute-column" key={index}>
                                <div className="attribute-name">{stat.posName}</div>
                                <div className="attribute-value">{stat.stat}</div>
                            </div>
                        ))}
                    </div>
                    <div className="attribute-section">
                        {/* Map the last three stats */}
                        {playerStats.slice(3).map((stat, index) => (
                            <div className="attribute-column" key={index}>
                                <div className="attribute-name">{stat.posName}</div>
                                <div className="attribute-value">{stat.stat}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {statsPopupOpen && <PlayerStatsPopup
                player={player}
                saveAllChanges={saveAllChanges}
                onClose={toggleStatsPopup}
                getPlayerStats={getPlayerStats} />}
        </div>

    );
};

export default PlayerCard;