import React, { useState, useEffect } from 'react';
import './MatchResultPopup.css';
import ManySimulationsPopup from './ManySimulationsPopup';
import { generateMatchSummary } from '../resources/openai/chatConfig';
import Ball from '../resources/soccerball.png';
import YellowCard from '../resources/yellow_card.png';
import RedCard from '../resources/red_card.png';
import MissedPenalty from '../resources/missed_penalty.png';
import yes from '../resources/yes.png';
import no from '../resources/no.png';
import In from '../resources/in.png';
import out from '../resources/out.png';
import { FaClipboard } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const MatchResultPopup = ({ teams, setIsVisible, results, setTeams, simulateAgain, checkTokens }) => {
    const [manyResults, setManyResults] = useState(null);
    const [simPopupVisible, setSimPopupVisible] = useState(false);
    const [matchType, setMatchType] = useState(false);
    const [matchCommentary, setMatchCommentary] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showCommentary, setShowCommentary] = useState(false);
    const [hasSubscription, setHasSubscription] = useState(false);
    const closePopup = () => {
        setIsVisible(false);
    };
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    useEffect(() => {
        const checkSubscription = async () => {
            try {
                const response = await axios.post("https://footballcustomformation.com/php_backend/paypal_api.php?action=check-subscription", { token }, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    withCredentials: true
                });
                setHasSubscription(response.data.hasSubscription);
            } catch (error) {
                console.error("Error checking subscription:", error);
            }
        };
        checkSubscription();
    }, [token]);

    //Makes sure that stoppage time doesn't happen after normal game time
    const parseMinute = (minute) => {
        const minuteStr = minute.toString().replace('(P)', '');

        if (minuteStr.includes('+')) {
            const [regular, stoppage] = minuteStr.split('+').map(Number);
            return regular + stoppage / 100;
        }
        return Number(minuteStr);
    }

    const sortEvents = (events) => {
        return events.sort((a, b) => parseMinute(a.minute) - parseMinute(b.minute));
    }

    const getSortedEvents = (teamKey) => {
        const goalEvents = results.goalScorers.map(scorer => ({ ...scorer, type: 'goal' }));
        const yellowCardEvents = results.yellowCards.map(card => ({ ...card, type: 'yellowCard' }));
        const redCardEvents = results.redCards.map(card => ({ ...card, type: 'redCard' }));
        const substitutionEvents = results.substitutions[teamKey].map(sub => ({ ...sub, type: 'substitution' }));
        const missedPenaltyEvents = results.missedPenalties.map(taker => ({ ...taker, type: 'missedPenalty' }));

        const allEvents = [
            ...goalEvents.filter(event => event.team === results[teamKey].displayName),
            ...yellowCardEvents.filter(event => event.team === results[teamKey].displayName),
            ...redCardEvents.filter(event => event.team === results[teamKey].displayName),
            ...substitutionEvents,
            ...missedPenaltyEvents.filter(event => event.team === results[teamKey].displayName)
        ];

        return sortEvents(allEvents);
    };

    const renderEvent = (event) => {
        switch (event.type) {
            case 'goal':
                return (
                    <div key={`${event.name}-${event.minute}`} className="goal-scorer">
                        <img src={Ball} alt="Goal" />
                        {event.name} {event.minute}'
                        {event.assister !== '' && (
                            <div className="assister">
                                Assisted by: {event.assister}
                            </div>
                        )}
                    </div>
                );
            case 'yellowCard':
                return (
                    <div key={`${event.name}-${event.minute}`} className="card">
                        <img src={YellowCard} alt="Yellow Card" />
                        {event.name} {event.minute}'
                    </div>
                );
            case 'redCard':
                return (
                    <div key={`${event.name}-${event.minute}`} className="card">
                        <img src={RedCard} alt="Red Card" />
                        {event.name} {event.minute}'
                    </div>
                );
            case 'substitution':
                return (
                    <div key={`${event.substitutedPlayer}-${event.minute}`} className="substitution">
                        <div className="sub-details">
                            <div className="sub-out">
                                {event.substitutedPlayer}
                                <img src={out} alt="Out" />
                            </div>
                            <div className="sub-in">
                                {event.substitute}
                                <img src={In} alt="In" />
                            </div>
                        </div>
                        <div className="minute">{event.minute}'</div>
                    </div>
                );
            case 'missedPenalty':
                return (
                    <div key={`${event.name}-${event.minute}`} className="card">
                        <img src={MissedPenalty} alt="Missed Penalty" />
                        {event.player} {event.minute}'
                    </div>
                );
            default:
                return null;
        }
    }

    const getPenalties = (teamName) => {
        if (!results.penalties) return null;

        const { penaltyLog } = results.penalties;

        return penaltyLog
            .filter(penalty => penalty.team === teamName)
            .map((penalty, index) => (
                <div key={index} className="penalty">
                    {teamName === results.team2.displayName && (
                        <img src={penalty.scored ? yes : no} alt={penalty.scored ? 'Scored' : 'Missed'} />
                    )}
                    {penalty.name}
                    {teamName === results.team1.displayName && (
                        <img src={penalty.scored ? yes : no} alt={penalty.scored ? 'Scored' : 'Missed'} />
                    )}
                </div>
            ));
    };

    const handleSimulate = async (numSimulations) => {
        let team1Wins = 0;
        let team2Wins = 0;
        let draws = 0;
        let playerGoalCounts = {};
        let playerAssistCounts = {};
        let scorelineCounts = {};
        let goalDifferences = {};
        let yellowCardCounts = {};
        let redCardCounts = {};

        const team1 = teams[0];
        const team2 = teams[1];

        for (let i = 0; i < numSimulations; i++) {
            const result = await simulateAgain(false);

            //Counts match outcomes
            if (result.team1Goals > result.team2Goals) {
                team1Wins++;
            } else if (result.team1Goals < result.team2Goals) {
                team2Wins++;
            } else {
                draws++;
            }

            //Count goals for each player
            if (result.goalScorers) {
                result.goalScorers.forEach(scorer => {
                    const playerName = scorer.name;
                    if (playerGoalCounts[playerName]) {
                        playerGoalCounts[playerName]++;
                    } else {
                        playerGoalCounts[playerName] = 1;
                    }

                    const assister = scorer.assister;
                    if (assister !== '') {
                        if (playerAssistCounts[assister]) {
                            playerAssistCounts[assister]++;
                        } else {
                            playerAssistCounts[assister] = 1;
                        }
                    }
                });

            }

            //Count yellow cards for each player
            if (result.yellowCards) {
                result.yellowCards.forEach(card => {
                    const playerName = card.name;
                    if (yellowCardCounts[playerName]) {
                        yellowCardCounts[playerName]++;
                    } else {
                        yellowCardCounts[playerName] = 1;
                    }
                });
            }

            //Count red card for each player
            if (result.redCards) {
                result.redCards.forEach(card => {
                    const playerName = card.name;
                    if (redCardCounts[playerName]) {
                        redCardCounts[playerName]++;
                    } else {
                        redCardCounts[playerName] = 1;
                    }
                });
            }

            const sortedGoalScorers = result.goalScorers.sort((a, b) => parseMinute(a.minute) - parseMinute(b.minute));

            const scoreline = `${result.team1Goals}-${result.team2Goals}`;
            const scorelineWithScorers = {
                scoreline: scoreline,
                goalScorers: sortedGoalScorers
            }

            if (scorelineCounts[scoreline]) {
                scorelineCounts[scoreline]++;
            } else {
                scorelineCounts[scoreline] = 1;
            }

            // Count goal differences
            const goalDifference = Math.abs(result.team1Goals - result.team2Goals);
            if (!goalDifferences[goalDifference]) {
                goalDifferences[goalDifference] = new Set();
            }
            goalDifferences[goalDifference].add(scorelineWithScorers);
        }

        //Counts and sorts goals and cards from most to least
        const playerGoalCountsArray = Object.keys(playerGoalCounts).map(playerName => ({
            name: playerName,
            goals: playerGoalCounts[playerName]
        }));
        playerGoalCountsArray.sort((a, b) => b.goals - a.goals);

        const playerAssistCountsArray = Object.keys(playerAssistCounts).map(playerName => ({
            name: playerName,
            assists: playerAssistCounts[playerName]
        }));
        playerAssistCountsArray.sort((a, b) => b.assists - a.assists);

        const yellowCardCountsArray = Object.keys(yellowCardCounts).map(playerName => ({
            name: playerName,
            yellowCards: yellowCardCounts[playerName]
        }));
        yellowCardCountsArray.sort((a, b) => b.yellowCards - a.yellowCards);

        const redCardCountsArray = Object.keys(redCardCounts).map(playerName => ({
            name: playerName,
            redCards: redCardCounts[playerName]
        }));
        redCardCountsArray.sort((a, b) => b.redCards - a.redCards);

        // Get top 5 most common scorelines
        const topScorelines = Object.entries(scorelineCounts)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 5)
            .map(entry => ({ scoreline: entry[0], count: entry[1] }));

        //Most onesided scores
        const maxGoalDifference = Math.max(...Object.keys(goalDifferences).map(Number));
        const mostOneSidedScores = [...goalDifferences[maxGoalDifference]];

        const finalResults = {
            team1Wins, team2Wins, draws,
            playerGoalCountsArray, playerAssistCountsArray,
            yellowCardCountsArray, redCardCountsArray,
            topScorelines, mostOneSidedScores,
            team1, team2
        };

        console.log(`${numSimulations} simulation results:`, finalResults);
        setTeams([team1, team2]);
        setManyResults(finalResults);
    };

    const parseCommentary = (text) => {
        const parts = text.split(/(\*\*.*?\*\*)/);
        return parts.map((part, index) => {
            if (part.startsWith('**') && part.endsWith('**')) {
                return <b key={index}>{part.slice(2, -2)}</b>
            } else {
                return <span key={index}>{part}</span>;
            }
        });
    }

    const getMatchSummary = async (results, matchType) => {
        //console.log('token:', token);
        setIsLoading(true);
        setShowCommentary(false);
        try {
            const summary = await generateMatchSummary(results, matchType, token);
            setMatchCommentary(summary);
            setShowCommentary(true);
        } catch (error) {
            console.error('Error fetching match summary:', error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleGetMatchCommentary = () => {
        if (checkTokens(false)) {
            if (window.confirm('Are you sure you want to use an AI token to get commentary?')) {
                getMatchSummary(results, matchType, token);
                checkTokens(true);
            }
        } else {
            if (window.confirm("You don't have any remaining tokens. Would you like to buy more here?")) {
                navigate(`/payment/${token}`);
            };
        }

        /*Use this later
        else {
            navigate(`/payment/${token}`);
        }
        */
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(matchCommentary)
            .then(() => alert('Commentary copied to clipboard!'))
            .catch(error => console.error('Could not copy text: ', error));
    }

    const ManOfMatchDisplay = ({ manOfTheMatch }) => {
        const formatRating = (rating) => {
            // Round the rating to the nearest tenth
            const roundedRating = Math.round(rating * 10) / 10;
            // Check if the rating is an integer and append .0 if needed
            return roundedRating % 1 === 0 ? `${roundedRating}.0` : roundedRating.toFixed(1);
        };

        return (
            <div className="man-of-the-match">
                <strong>Man of the Match:</strong> {manOfTheMatch.player}: {formatRating(manOfTheMatch.rating)} (<i>{manOfTheMatch.team}</i>)
            </div>
        );
    };

    if (simPopupVisible) {
        return (
            <ManySimulationsPopup
                onSimulate={handleSimulate}
                onClose={() => setIsVisible(false)}
                results={manyResults}
                goBack={() => setSimPopupVisible(false)}
            />
        );
    }

    return (
        <div className={`popup-container ${simPopupVisible ? 'large' : ''}`}>
            <div className="popup-content">
                <button className="close-button" onClick={closePopup}>X</button>
                <div className="teams-container">
                    <div className="team-result">
                        <h1 className="team-name">{results.team1.displayName}</h1>
                        <p className="team-score">
                            {results.team1Goals}
                            {results.penalties && `(${results.penalties.team1Score})`}
                        </p>
                        <div className="events">
                            {getSortedEvents('team1').map(renderEvent)}
                        </div>
                    </div>
                    <div className="team-result">
                        <h1 className="team-name">{results.team2.displayName}</h1>
                        <p className="team-score">
                            {results.team2Goals}
                            {results.penalties && `(${results.penalties.team2Score})`}
                        </p>
                        <div className="events">
                            {getSortedEvents('team2').map(renderEvent)}
                        </div>
                    </div>
                </div>
                {results.penalties && (
                    <div className="penalties-container">
                        <div className="penalties-heading">Penalties</div>
                        <div className="penalty-team-container">
                            <div className="penalty-team">
                                <h3>{results.team1.displayName}</h3>
                                {getPenalties(results.team1.displayName)}
                            </div>
                            <div className="penalty-team">
                                <h3>{results.team2.displayName}</h3>
                                {getPenalties(results.team2.displayName)}
                            </div>
                        </div>
                    </div>
                )}
                <ManOfMatchDisplay manOfTheMatch={results.manOfTheMatch}/>

                <div className="switch-container">
                    <span className={`switch-label ${!matchType ? 'active' : ''}`}>League</span>
                    <div
                        className={`switch ${matchType ? 'on' : 'off'}`}
                        onClick={() => setMatchType(prevType => !prevType)}
                    >
                        <div className={`switch-knob ${matchType ? 'on' : 'off'}`}></div>
                    </div>
                    <span className={`switch-label ${matchType ? 'active' : ''}`}>Cup</span>
                    <div className="tooltip-container">
                        <div className="question-mark">?</div>
                        <div className="tooltip-message">A League match has draws without tie breakers, while a Cup match involves penalty shootouts to determine a winner</div> {/* NEW */}
                    </div>
                </div>
                <button className="simulate-button" onClick={() => simulateAgain(matchType)}>Simulate again</button>
                <button className="simulate-button" onClick={() => setSimPopupVisible(true)}>Simulate x Times</button>
                <button className="simulate-button" onClick={handleGetMatchCommentary}>Get Match Commentary</button>

                {isLoading && (
                    <div className="loading-container">
                        <div className="loading-bar">
                            <div className="loading-progress"></div>
                        </div>
                        <p>Loading from ChatGPT...</p>
                    </div>
                )}

                {showCommentary && (
                    <div className="commentary-container">
                        <button className="close-button" onClick={() => setShowCommentary(false)}>X</button>
                        <div className="commentary-content">
                            {matchCommentary.split('\n').map((line, index) => (
                                <p key={index}>{parseCommentary(line)}</p>
                            ))}
                        </div>
                        <div className="copy-container">
                            <button className="copy-button" onClick={copyToClipboard}>
                                <FaClipboard /> Copy
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MatchResultPopup;