import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import './stats-popup.css';
import { calculateOverall } from './OverallCalculation';
import { fetchLogos } from './SearchBar';
import whiteStar from './resources/white_star.png';
import yellowStar from './resources/yellow_star.png';
import { fetchPlayerData } from './PlayerCardComponents/PlayerDataFetcher';

const token = localStorage.getItem('token');

const fieldMappings = {
    id: 'ID',
    fullName: 'Full Name',
    age: 'Age',
    nationality: 'Nationality',
    overall: 'Overall',
    potential: 'Potential',
    club: 'Club',
    position: 'Position',
    height: 'Height',
    prefFoot: 'Preferred Foot',
    weakFoot: 'Weak Foot',
    skillMoves: 'Skill Moves',
    intRep: 'International Reputation',
    pace: 'Pace',
    acceleration: 'Acceleration',
    sprintSpeed: 'Sprint Speed',
    shooting: 'Shooting',
    positioning: 'Alt Positioning',
    finishing: 'Finishing',
    shotPower: 'Shot Power',
    longShots: 'Long Shots',
    volleys: 'Volleys',
    penalties: 'Penalties',
    passing: 'Passing',
    vision: 'Vision',
    crossing: 'Crossing',
    fkAccuracy: 'FK Accuracy',
    shortPassing: 'Short Passing',
    longPassing: 'Long Passing',
    curve: 'Curve',
    dribbling: 'Dribbling Overall',
    agility: 'Agility',
    balance: 'Balance',
    reactions: 'Reactions',
    ballControl: 'Ball Control',
    skill_dribbling: 'Dribbling Skill',
    composure: 'Composure',
    defending: 'Defending',
    interceptions: 'Interceptions',
    headingAccuracy: 'Heading Accuracy',
    marking: 'Marking',
    standingTackle: 'Standing Tackle',
    slidingTackle: 'Sliding Tackle',
    physical: 'Physical',
    jumping: 'Jumping',
    stamina: 'Stamina',
    strength: 'Strength',
    aggression: 'Aggression',
    gkDiving: 'GK Diving',
    gkHandling: 'GK Handling',
    gkKicking: 'GK Kicking',
    gkReflex: 'GK Reflex',
    gkPos: 'GK Positioning',
};

const PlayerStatsPopup = ({ player, onClose, saveAllChanges, getPlayerStats }) => {
    const [editingStat, setEditingStat] = useState(null);
    const [editedPlayer, setEditedPlayer] = useState({ ...player });
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [birthday, setBirthday] = useState('');
    const [showBirthdayInput, setShowBirthdayInput] = useState(false);

    /** variables for changing Player's club */
    const [logos, setLogos] = useState([]);
    const [showClubDropdown, setShowClubDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        const checkEditAbility = async () => {
            try {
                const response = await axios.get('https://footballcustomformation.com/php_backend/get_username.php', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                setCanEdit(response.data.canEdit);
            } catch (error) {
                console.log('Cannot find username', error.response);
            }
        };
        checkEditAbility();
    }, [token]);

    /** Functions for getting results for clubs */
    const handleClubDoubleClick = useCallback(() => {
        setShowClubDropdown(true);
    }, []);

    //Sets logos array when club field is double clicked
    useEffect(() => {
        fetchLogos(setLogos, null);
    }, [handleClubDoubleClick]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const selectClub = async (club) => {
        const originalClub = editedPlayer.club;
        setEditedPlayer({ ...editedPlayer, club: club.club, clubLogo: club.logo });
        setShowClubDropdown(false);
        setSearchTerm('');
        if (saveAllChanges !== undefined && canEdit) {
            try {
                await axios.post('https://footballcustomformation.com/php_backend/fetch_teamId.php', {
                    teamName: club.club,
                    playerId: editedPlayer.id
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                });

                saveAllChanges(editedPlayer);
            } catch (error) {
                console.error(`Player unable to be added to reserves of ${club.club}`, error.response);
            }
        }
    };

    const filteredClubs = logos.filter(logo => logo.club.toLowerCase().includes(searchTerm.toLowerCase()));

    //End of functions for search Clubs
    useEffect(() => {
        //Calculates age based on birthday
        const calculateAndStoreAge = () => {
            // Basic validation for birthday format (YYYY-MM-DD)
            if (!/^\d{4}-\d{2}-\d{2}$/.test(birthday)) {
                alert("Please enter the birthday in 'YYYY-MM-DD' format.");
                return;
            }

            const birthdayDate = new Date(birthday);
            const today = new Date();
            let age = today.getFullYear() - birthdayDate.getFullYear();
            const m = today.getMonth() - birthdayDate.getMonth();

            //If birthday hasn't passed in the year
            if (m < 0 || (m === 0 && today.getDate() < birthdayDate.getDate())) {
                age--;
            }

            const ageString = `${birthday}; ${age}`;
            setEditedPlayer({ ...editedPlayer, age: ageString });
            setShowBirthdayInput(false);
        }

        if (birthday) { // Only calculate and store age if birthday is not empty
            calculateAndStoreAge();
        }
    }, [birthday]);

    //Waits for control z and control y command
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.ctrlKey && e.key === 'z') {
                undoEdit();
            }
            if (e.ctrlKey && e.key === 'y') {
                redoEdit();
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [undoStack, redoStack]);

    const undoEdit = () => {
        if (undoStack.length > 0) {
            const previousState = undoStack.pop();
            setRedoStack([...redoStack, { ...editedPlayer }]);
            setEditedPlayer(previousState);
        }
    };

    const redoEdit = () => {
        if (redoStack.length > 0) {
            const nextState = redoStack.pop();
            setUndoStack([...undoStack, { ...editedPlayer }]);
            setEditedPlayer(nextState);
        }
    }

    //Changes the value of the birthday after input
    const handleBirthdayChange = (e) => {
        setBirthday(e.target.value);
    };

    const handleDoubleClick = fieldName => {
        if (fieldName === 'age') {
            setShowBirthdayInput(true);
        } else if (fieldName === 'club') {
            handleClubDoubleClick();
        } else if (fieldName === 'id') {
            return;
        } else {
            setEditingStat(fieldName);
        }
    }

    const handleChange = (e, fieldName) => {
        setUndoStack([...undoStack, { ...editedPlayer }]);
        setRedoStack([]);
        setEditedPlayer(prevState => ({
            ...prevState,
            [fieldName]: e.target.value,
        }));
    }

    const handleCalculate = (editedPlayer) => {
        editedPlayer.playerMax = calculateOverall(editedPlayer);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setEditingStat(null);
        }
    };

    const getStatColor = (value) => {
        if (value <= 50) return 'red';
        if (value <= 70) return 'yellow';
        if (value <= 80) return 'limegreen';
        return 'darkgreen';
    }

    const handleSaveForm = async () => {
        const stats = await fetchPlayerData(player);
        if (window.confirm('Do these stats look alright?')) {

            player.goals = stats.goals;
            player.assists = stats.assists;
            player.cleanSheets = stats.cleanSheets;
            player.form = stats.form;
            saveAllChanges(player);
        }
    }

    return ReactDOM.createPortal(
        <>
            {showBirthdayInput && (
                <div className="birthday-input-popup">
                    <input
                        type="text"
                        value={birthday}
                        onChange={handleBirthdayChange}
                        placeholder="YYYY-MM-DD"
                        onKeyDown={(e) => e.key === 'Enter' && setBirthday(e.target.value)} />
                    <button onClick={() => setBirthday(birthday)}>Save Age</button>
                </div>
            )}

            {/**Search dropdown for changing the player's club */}
            {showClubDropdown && (
                <div className="club-dropdown">
                    <input type="text" placeholder="Search clubs..." onChange={handleSearchChange} autoFocus />
                    <ul>
                        {filteredClubs.map((club, index) => (
                            <li key={index} onClick={() => selectClub(club)}>
                                {club.club} <img src={club.logo} alt={club.club} style={{ width: '20px', height: '20px' }} />
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            <div className="player-stats-popup">
                <div className="player-cards-container">
                    <h2 style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={player.photo} style={{ width: '70px', height: '70px', padding: '10px' }} /> {player.name}'s Stats
                        {canEdit && <button style={{ marginLeft: '10px' }} onClick={handleSaveForm}>Get Form</button>}
                    </h2>
                    <ul>
                        {Object.entries(fieldMappings).map(([fieldName, fieldLabel]) => (
                            <li key={fieldName} onDoubleClick={() => handleDoubleClick(fieldName)}>
                                {fieldLabel}: {['Weak Foot', 'Skill Moves', 'International Reputation'].includes(fieldLabel) ? (
                                    <div>
                                        {Array.from({ length: 5 }, (_, index) => (
                                            <img
                                                key={index}
                                                src={index < editedPlayer[fieldName] ? yellowStar : whiteStar}
                                                alt="star"
                                                style={{ width: '20px', height: '20px' }}
                                            />
                                        ))}
                                    </div>
                                ) : fieldName === 'id' ? (
                                    editedPlayer[fieldName] || 'N/A' // Display ID as plain text
                                ) : fieldName === 'height' ? (
                                    editedPlayer[fieldName]
                                ) : editingStat === fieldName ? (
                                    <input
                                        type="text"
                                        value={editedPlayer[fieldName]}
                                        onChange={(e) => handleChange(e, fieldName)}
                                        onKeyDown={(e) => handleKeyDown(e)}
                                        autoFocus
                                    />
                                ) : (
                                    isNaN(parseInt(editedPlayer[fieldName])) || !/^\d+$/.test(editedPlayer[fieldName].toString()) ? (
                                        editedPlayer[fieldName] || 'N/A'
                                    ) : (
                                        <>
                                            <span>{editedPlayer[fieldName]}</span>
                                            <div className="stat-bar-container">
                                                <div
                                                    className="stat-bar-filled"
                                                    style={{
                                                        width: `${Math.min(editedPlayer[fieldName], 100)}%`,
                                                        backgroundColor: getStatColor(editedPlayer[fieldName]),
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                <button onClick={onClose} onTouchEnd={onClose}>Close</button>
                {saveAllChanges !== undefined && canEdit &&
                    (<button onClick={() => saveAllChanges(editedPlayer)} onTouchEnd={() => saveAllChanges(editedPlayer)} style={{ marginTop: "10px" }}>Save All Changes</button>)}
                <button onClick={() => handleCalculate(editedPlayer)}>Calculate Overall</button>
                {getPlayerStats !== undefined && canEdit && (
                    <button onClick={() => getPlayerStats(editedPlayer, `http://localhost:3001/FIFAFormationCreator/player/${editedPlayer.id}`)}
                        disabled={!(typeof getPlayerStats === 'function')}>Get Stats from SoFIFA</button>)}
            </div>
        </>,
        document.getElementById('portal-root')
    );
    //Save this link for future: https://footballcustomformation.com/php_backend/web_server.php
};

export default PlayerStatsPopup;